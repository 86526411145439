import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Clients from "../components/clients"
import CaseStudies from "../components/work/caseStudies"
import Description from "../components/description"
import PageData from "../components/pageData"

const PageWork = ({ data }) => {
  const page = data.wpPage

    console.log("PAGEX", page.WorkData)

  return (
    <Layout id="work">
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />
      <div className="page-header">
        <PageData {...page.PageData} />
        <div className="page-header-copy">
          <h1>{page.title}</h1>
          <Description {...page.Description} />
        </div>
      </div>

      <Clients clients={page.WorkData.clients} />
      <div className="page-dark">
        <CaseStudies />
      </div>
    </Layout>
  )
}

export default PageWork

// TODO: extract query under `WorkData` into graphql fragment
export const query = graphql`query ($slug: String) {
  wpPage(slug: {eq: $slug}) {
    title
    Description {
      description
    }
    PageData {
      megaNumber
      miniText
    }
    SeoMeta {
      seoTitle
      seoDescription
    }
    WorkData {
      clients {
        ... on WpClient {
          title
          ClientData {
            
            market {
              name
              databaseId
            }
            logo {
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 320, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
}
`
