import React from "react"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { getUniqueContinents } from '../../helper'
import * as style from './casestudies.module.scss'

const CaseStudiesItem = (props) => {
  const { Description, Header, ProjectData, link, title } = props
  return (
    <Link className={style.caseStudiesItem} to={link} title={title}>
      <div className={style.columnImage}>
        <GatsbyImage
          image={Header.headerImage.localFile.childImageSharp.gatsbyImageData}
          alt={Header.headerImage.altText}
          title={Header.headerImage.title} />
      </div>
      <div className={style.columnCopy}>
        <div className={style.continent}>{getUniqueContinents(ProjectData.offices).join(', ')}</div>
        <h3>{title}</h3>
        <div dangerouslySetInnerHTML={{ __html: Description.description }} />
        <div className={style.learnMore}>Dowiedz się więcej &rarr;</div>
      </div>
    </Link>
  );
}

CaseStudiesItem.propTypes = {
  Description: PropTypes.object.isRequired,
  Header: PropTypes.object.isRequired,
  ProjectData: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default CaseStudiesItem
