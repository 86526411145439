import React, { Component } from 'react'
import CaseStudiesItem from './caseStudiesItem'
import Select from 'react-select'

import FilterGroup from '../filterGroup'
import FilterWrap from '../filterWrap'
import FilterReset from '../filterReset'
import { getMarkets, getCategories } from '../../helper'
import { filterStylesDark } from '../filterStyles'
import * as style from './casestudies.module.scss'

const PROJECTS_TO_DISPLAY = 6

class CaseStudiesWrap extends Component {
  constructor(props) {
    super()

    this.state = {
      showCount: PROJECTS_TO_DISPLAY,
      markets: null,
      categories: null
    }

    this.categories = getCategories(props.projects)
    this.markets = getMarkets(props.projects)
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleReset() {
    this.setState({
      markets: null,
      categories: null
    })
  }

  handleFilterBlur(event, label) {
    this.setState({[label]: {...event}})
  }

  handleLoadMore() {
    this.setState({
      showCount: this.state.showCount + PROJECTS_TO_DISPLAY
    })
  }

  render() {
    let projects = this.props.projects
    if (this.state.categories && this.state.categories.value) {
      projects = projects.filter(project => project.ProjectData.category === this.state.categories.value)
    }
    if (this.state.markets && this.state.markets.value) {
      projects = projects.filter(project => project.ProjectData.market && project.ProjectData.market.name === this.state.markets.value)
    }

    return (
      <section id={style.caseStudies}>
        <div className={style.header}>
          <h2>Case Studies</h2>
          <p>Co robimy &ndash; i jak to robimy.</p>
        </div>

        <FilterGroup theme="dark">
          <FilterWrap theme="dark">
            <Select
              styles={filterStylesDark}
              placeholder='Wybierz kategorię...'
              onChange={ (event) => { this.handleFilterBlur(event, 'categories') } }
              options={this.categories}
              value={this.state.categories}
              isSearchable={false}
            />
          </FilterWrap>
          <FilterWrap theme="dark">
            <Select
              styles={filterStylesDark}
              placeholder='Wybierz rynek...'
              onChange={ (event) => { this.handleFilterBlur(event, 'markets') } }
              options={this.markets}
              value={this.state.markets}
              isSearchable={false}
            />
          </FilterWrap>
        </FilterGroup>

        <div className={style.caseStudiesWrap}>
          {projects
            .slice(0, this.state.showCount)
            .map(project => <CaseStudiesItem key={project.title} {...project} />)
          }
          { projects.length === 0 && (
            <FilterReset callback={this.handleReset} />
          )}
        </div>

        {projects.length > this.state.showCount && (
          <div className="mh-button-wrap">
            <button className="mh-button invert" onClick={this.handleLoadMore}>Zobacz więcej realizacji</button>
          </div>
        )}
      </section>
    )
  }
}

export default CaseStudiesWrap
