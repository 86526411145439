import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CaseStudiesWrap from './caseStudiesWrap'

const CaseStudies = () => {
  const data = useStaticQuery(graphql`{
  allWpProject(sort: {order: DESC, fields: date}) {
    nodes {
      link
      title
      Description {
        description
      }
      Header {
        headerImage {
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(width: 720, layout: CONSTRAINED)
            }
          }
        }
      }
      ProjectData {
        category
        market {
          name
          databaseId
        }
        offices {
          ... on WpOffice {
            OfficeData {
              continent
            }
          }
        }
      }
    }
  }
}
`)

  return <CaseStudiesWrap projects={data.allWpProject.nodes} />
}

export default CaseStudies
